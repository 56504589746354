
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "user-showProfile",
  components: {},
  data() {
    return {
      api_url: "",
      userInfo: {
        employee: {
          id: "" as any,
        },
      },
      load: false,
      employee: {
        id: "",
        name: "",
        designation: "",
        nid: "",
        passport: "",
        driving_license: "",
        dob: "",
        email: "",
        mobile: "",
        present_address: "",
        present_district: "" as any,
        permanent_address: "" as any,
        permanent_district: "" as any,
        father_name: "",
        mother_name: "",
        employee_image: "" as any,
        religion: "",
        gender: "",
      },
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.userInfo = VueCookieNext.getCookie("_seip_user");
    await this.getEmployeeProfile();
  },
  methods: {
    async getEmployeeProfile() {
      this.load = true;
      await ApiService.get(
        "configurations/employee/show/" + this.userInfo.employee.id
      )
        .then((response) => {
          this.load = false;

          this.employee = response.data.data;
          console.log(this.employee);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
